import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Layout } from '../../components/Layout'
import { ArrowDownIcon } from '../../components/Icon'

import { Button } from '../../components/Button'
import { Heading2 } from '../../components/Heading'
import { NewsData, NewsList, NewsTag } from '../../components/NewsList'
import { sortByOrder, sortTop, usePagination } from '../../utils/pagination'
import { useTranslation } from '../../i18n'

import './index.scss'

interface FilterOption {
  label: string
  value: string
  icon?: ServerImageData
}
interface ListProps {
  filterOptions: FilterOption[]
  data: APIListResponse<NewsData>
  pageSize?: number
  title?: string
}

export const DesktopList: React.FC<ListProps> = ({
  filterOptions,
  data,
  pageSize = 6,
  title,
}) => {
  const [selected, setSelected] = useState('*')
  const { t } = useTranslation({ zh: {}, en: {} })

  // Memoize filter function
  const filter = useCallback(
    (data: ResponseData<NewsData>) => {
      if (selected === '*') {
        return true
      }
      return data.attributes.tag?.data?.attributes.name === selected
    },
    [selected]
  )

  // Add proper dependencies to usePagination
  const pagination = usePagination(data, { page: 1, pageSize }, filter)

  // Add error boundary for missing data
  if (!data?.data) {
    return null
  }

  return (
    <div className="desktop">
      <div className="selection">
        {filterOptions.map((filter) => {
          return (
            <Button
              key={filter.value}
              border={selected === filter.value}
              onClick={() => {
                setSelected(filter.value)
              }}>
              <NewsTag name={filter.label} icon={filter.icon} />
            </Button>
          )
        })}
      </div>
      <NewsList data={pagination.list} title={title} />
      {pagination.hasMore && (
        <Button border className="more" onClick={() => pagination.next()}>
          {t('more')}
        </Button>
      )}
    </div>
  )
}

const MobileListItem: React.FC<{
  selected: string
  filter: FilterOption
  onChange: (key: string) => void
  data: ListProps['data']
  title?: string
}> = ({ selected, onChange, filter, data, title }) => {
  const filterFn = useCallback(
    (data: ResponseData<NewsData>) => {
      if (selected === '*') {
        return true
      }
      return data.attributes.tag?.data?.attributes?.name === selected
    },
    [selected]
  )
  const pagination = usePagination(data, { page: 1, pageSize: 6 }, filterFn)

  return (
    <div>
      <Button
        className="filter"
        border={true}
        onClick={() => onChange(filter.value === selected ? '' : filter.value)}
        open={filter.value === selected}>
        <NewsTag name={filter.label} icon={filter.icon}></NewsTag>
      </Button>
      <div style={{ display: selected === filter.value ? 'block' : 'none' }}>
        <NewsList data={pagination.list} title={title} />
        {pagination.hasMore && (
          <Button border className="mobile-down" onClick={pagination.next}>
            <ArrowDownIcon />
          </Button>
        )}
      </div>
    </div>
  )
}

const MobileList: React.FC<ListProps> = ({ filterOptions, data }) => {
  const [selected, setSelected] = useState('all')

  return (
    <div className="mobile">
      {filterOptions.map((filter) => {
        return (
          <MobileListItem
            key={filter.value}
            onChange={(key) => setSelected(key)}
            selected={selected}
            filter={filter}
            data={data}
          />
        )
      })}
    </div>
  )
}

interface NewsPageData {
  title: string
  head: SectionCard
  filter: Record<string, string>
}

interface NewsTag {
  name: string
  icon: ServerImageData
}

export const NewsPage: React.FC<
  PageData<{
    newsList: APIListResponse<NewsData>
    newsPage: APIResponse<NewsPageData>
    newsTag: APIListResponse<NewsTag>
  }>
> = ({ pageContext }) => {
  const {
    serverData: { newsList, newsPage, newsTag },
  } = pageContext
  const { t } = useTranslation({ zh: {}, en: {} })

  const filterOptions: FilterOption[] = [
    { label: t('Filter All'), value: '*' },
  ].concat(
    sortByOrder(newsTag.data).map((tag) => {
      return {
        label: tag.attributes.name,
        value: tag.attributes.name,
        icon: tag.attributes.icon,
      }
    })
  )

  sortTop(newsList.data)

  return (
    <Layout className="new-page">
      <Helmet>
        <title>{newsPage.data.attributes.title}</title>
      </Helmet>
      <Container className="head">
        <Heading2>{newsPage.data.attributes.head.title}</Heading2>
      </Container>
      {/* <TrendsCard data={newsList.data} /> */}
      <Container className="list">
        <DesktopList filterOptions={filterOptions} data={newsList} />
        <MobileList filterOptions={filterOptions} data={newsList} />
      </Container>
    </Layout>
  )
}
