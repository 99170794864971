import React, { useEffect, useRef, useState, useCallback } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container } from '../Layout'
import { Heading3, Heading4, Heading6 } from '../Heading'
import { ForwardIcon, TelescopeIcon } from '../Icon'
import { resolveServerImg } from '../../utils'
import { Link } from '../Link'
import { Slider, SliderMethods } from '../Slider'
import throttle from 'lodash.throttle'
import { useIsMd } from '../../utils/media'
import { useI18n } from '../../i18n'

import './index.scss'
interface ReportItem {
  id: number
  locale: string
  title: string
  cover?: ServerImageData
  content: string
}
export interface ReportCardProps extends React.HTMLAttributes<HTMLDivElement> {
  data: ReportItem
}

export type ReportData = APIListResponse<ReportItem>

export const ReportCard: React.FC<ReportCardProps> = ({
  id,
  data,
  ...rest
}) => {
  const { title, cover, locale, content } = data
  const imageData = cover ? resolveServerImg(cover) : null
  const image = imageData ? getImage(imageData) : null
  const { isEnglish } = useI18n()

  return (
    <div className="report-card" {...rest}>
      <Link to={`/${locale}/reports/${id}`} external={true}>
        <Heading3 className="vh">{title}</Heading3>
        {image && <GatsbyImage image={image} alt={title} />}
        {!!content && (
          <div className="read-more">
            <Heading6>{isEnglish ? 'View Report' : '查看报告'}</Heading6>
            <ForwardIcon />
          </div>
        )}
      </Link>
    </div>
  )
}

export const Reports: React.FC<{ reports: ReportData; title: string }> = ({
  reports,
  title,
}) => {
  const listRef = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<SliderMethods>(null)
  const [width, setWidth] = useState<string | number>(0)
  const isMd = useIsMd()
  const padding = isMd ? 24 : 0
  const reportsData = reports.data.filter((item) => {
    return item.id !== 13
  })

  const calc = useCallback(
    throttle(() => {
      if (listRef.current) {
        const rect = listRef.current.getBoundingClientRect()
        setWidth(isMd ? (rect.width - padding) / 2 : rect.width)
      }
    }, 100),
    [isMd, padding],
  )

  useEffect(() => {
    const calcFn = calc
    calc()
    window.addEventListener('resize', calcFn)
    return () => {
      window.removeEventListener('resize', calcFn)
      calc.cancel()
    }
  }, [calc])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.reCalc()
    }
  }, [width])

  return (
    <Container className="reports">
      <div className="title">
        <TelescopeIcon />
        <Heading4>{title}</Heading4>
      </div>
      <div
        className={`list ${reportsData.length <= 2 ? 'hide' : ''}`}
        ref={listRef}>
        <Slider
          container={listRef}
          padding={padding}
          ref={sliderRef}
          drag={reportsData.length > 2}>
          {reportsData.map((item, index) => {
            return (
              <ReportCard
                key={item.id + index.toString()}
                data={item.attributes}
                id={item.id.toString()}
                style={{ width }}
              />
            )
          })}
        </Slider>
      </div>
    </Container>
  )
}
